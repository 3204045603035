import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import BeforeLogin from "./components/Beforelogin";
import RegisterForm from "./components/RegisterForm";
import LoginForm from "./components/LoginForm";
import AfterLogin from "./components/Afterlogin";
import Profile from "./components/Profile";
import Course from "./components/Course";
import MentorProfile from "./components/MentorProfile"; // Import halaman profil mentor
import Mentor1 from "./page/Mentor1";
import Mentor2 from "./page/Mentor2";
import Mentor3 from "./page/Mentor3";
import Mentor4 from "./page/Mentor4";
import Mentor5 from "./page/Mentor5";
import Mentor6 from "./page/Mentor6";
import Mentor7 from "./page/Mentor7";
import Mentor8 from "./page/Mentor8";
import Mentor9 from "./page/Mentor9";
import PaymentTest from "./components/PaymentTest";
import Soal1 from "./data/Soal1";
import Soal2 from "./data/Soal2";
import Soal3 from "./data/Soal3";
import Soal4 from "./data/Soal4";
import Soal5 from "./data/Soal5";
import Soal6 from "./data/Soal6";
import Soal7 from "./data/Soal7";
import Soal8 from "./data/Soal8";
import Soal9 from "./data/Soal9";
import AuthOutlet from "@auth-kit/react-router/AuthOutlet";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BeforeLogin />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route element={<AuthOutlet fallbackPath="/login" />}>
          <Route path="/afterlogin" element={<AfterLogin />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/course" element={<Course />} /> {/* Add Course route */}
        </Route>
        <Route path="/mentor/:mentorName" element={<MentorProfile />} />{" "}
        {/* Add dynamic mentor profile route */}
        <Route path="/page/mentor1" element={<Mentor1 />} />
        <Route path="/page/mentor2" element={<Mentor2 />} />
        <Route path="/page/mentor3" element={<Mentor3 />} />
        <Route path="/page/mentor4" element={<Mentor4 />} />
        <Route path="/page/mentor5" element={<Mentor5 />} />
        <Route path="/page/mentor6" element={<Mentor6 />} />
        <Route path="/page/mentor7" element={<Mentor7 />} />
        <Route path="/page/mentor8" element={<Mentor8 />} />
        <Route path="/page/mentor9" element={<Mentor9 />} />
        <Route path="/paymenttest" element={<PaymentTest />} />
        <Route path="/soal1" element={<Soal1 />} />
        <Route path="/soal2" element={<Soal2 />} />
        <Route path="/soal3" element={<Soal3 />} />
        <Route path="/soal4" element={<Soal4 />} />
        <Route path="/soal5" element={<Soal5 />} />
        <Route path="/soal6" element={<Soal6 />} />
        <Route path="/soal7" element={<Soal7 />} />
        <Route path="/soal8" element={<Soal8 />} />
        <Route path="/soal9" element={<Soal9 />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
