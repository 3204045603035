import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader"; // Use the appropriate hook from your auth library
import "../style/Course.css";

const Course = () => {
  const [courses, setCourses] = useState([]);
  const authHeader = useAuthHeader(); // Get the auth header with the token

  useEffect(() => {
    fetchCourses();
  });

  const fetchCourses = () => {
    fetch("https://eduyouwebbers.my.id/api/courses", {
      headers: {
        Authorization: authHeader, // Include the token in the request headers
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        return response.json();
      })
      .then((data) => {
        setCourses(data);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  };

  const handleRemoveCourse = (courseId) => {
    fetch(`https://eduyouwebbers.my.id/api/mentors/${courseId}`, {
      method: "DELETE",
      headers: {
        Authorization: authHeader, // Remove the function call here
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete course");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.message);
        const updatedCourses = courses.filter(
          (course) => course.id !== courseId
        );
        setCourses(updatedCourses);
      })
      .catch((error) => {
        console.error("Error deleting course:", error);
      });
  };
  

  return (
    <div className="course-container">
      <h1 className="course-header">Detail Kursus</h1>
      {courses.map((course) => (
        <div className="mentor-card" key={course.id}>
          <h2>
            Mengikuti Kelas:{" "}
            <Link to={`/mentor/${course.mentorName}`}>{course.mentorName}</Link>
          </h2>
          <p>Materi: {course.courseTaught}</p>
          <p>Harga: {course.mentorPrice}</p>
          {/* Tambahkan informasi lain dari course */}
          <button onClick={() => handleRemoveCourse(course.id)}>
            Hapus Kursus
          </button>
        </div>
      ))}
      <div>
        <Link to="/Afterlogin">
          <button className="kembali">Kembali</button>
        </Link>
      </div>
    </div>
  );
};

export default Course;
