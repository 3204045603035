import React, { useState, useEffect } from "react";
import Htmlmentor from "../assets/htmlmentor.png";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../style/Mentor1.css";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const Mentor5 = () => {
  const mentorName = "Leona"; // Ensure this matches the mentor's name in the database
  const mentorPrice = 120000;
  const courseTaught = "Bahasa Indonesia";

  const [selectedDay, setSelectedDay] = useState("");
  const [rating, setRating] = useState(0);
  const [experience, setExperience] = useState("");
  const [name, setName] = useState("");
  const [experiences, setExperiences] = useState([]);
  const authUser = useAuthUser();

  useEffect(() => {
    // Fetch existing experiences from backend when component mounts
    fetch(`https://eduyouwebbers.my.id/api/experiences/${mentorName}`)
      .then((response) => response.json())
      .then((data) => setExperiences(data))
      .catch((error) => console.error("Error fetching experiences:", error));
  }, [mentorName]);

  const handlePilihMentor = () => {
    fetch("https://eduyouwebbers.my.id/api/mentors", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mentorName: mentorName,
        mentorPrice: mentorPrice.toString(),
        courseTaught: courseTaught,
        selectedDay: selectedDay,
        user_id: authUser.userId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save mentor data");
        }
        window.location.href = `/PaymentTest?mentorPrice=${encodeURIComponent(
          mentorPrice
        )}&mentorName=${encodeURIComponent(
          mentorName
        )}&courseTaught=${encodeURIComponent(courseTaught)}`;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleShareExperience = () => {
    fetch("https://eduyouwebbers.my.id/api/experiences", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mentorName: mentorName,
        rating: rating,
        experience: experience,
        name: name,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to share experience");
        }
        return response.json();
      })
      .then((newExperience) => {
        setExperiences([...experiences, newExperience]);
        alert("Pengalaman Anda telah berhasil dibagikan!");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="mentor-page">
      <img src={Htmlmentor} alt="Html mentor" />
      <h1>{mentorName}</h1>
      <h2>{courseTaught}</h2>
      <div className="mentor-info">
        <div className="icon-star">
          <div className="Rate">
            <p>100% Rate</p>
          </div>
        </div>
        <p>
          Leona memiliki rating 5 bintang dari para muridnya, menunjukkan
          kualitas pembelajaran yang dia berikan.
        </p>
        <h4>
          "Bahasa Indonesia adalah proses mengajarkan dan mempelajari Bahasa
          Indonesia, baik sebagai bahasa ibu maupun sebagai bahasa kedua. Proses
          ini mencakup penguasaan aspek-aspek kebahasaan seperti tata bahasa,
          kosakata, pelafalan, keterampilan membaca, menulis, mendengarkan, dan
          berbicara. Tujuannya adalah agar peserta didik mampu menggunakan
          Bahasa Indonesia secara efektif dan tepat dalam berbagai konteks
          komunikasi, baik lisan maupun tulisan."
        </h4>
      </div>

      <div className="day-selection">
        <label htmlFor="day">Pilih Hari:</label>
        <select
          id="day"
          value={selectedDay}
          onChange={(e) => setSelectedDay(e.target.value)}
        >
          <option value="">--Pilih Hari--</option>
          <option value="Senin">Senin</option>
          <option value="Selasa">Selasa</option>
          <option value="Rabu">Rabu</option>
          <option value="Kamis">Kamis</option>
          <option value="Jumat">Jumat</option>
          <option value="Sabtu">Sabtu</option>
        </select>
      </div>

      <button className="pilih" onClick={handlePilihMentor}>
        Pilih Sebagai Mentor
      </button>

      <div className="form-group">
        <p className="share1">Bagikan Pengalaman Anda</p>
        {[...Array(5)].map((_, i) => {
          const ratingValue = i + 1;
          return (
            <label key={i}>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => setRating(ratingValue)}
              />
              <FaStar
                className="star-icon"
                color={ratingValue <= rating ? "#ffc107" : "#e4e5e9"}
              />
            </label>
          );
        })}
        <div className="name-user">
          Nama Anda:
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <p className="experience">Pengalaman Anda:</p>
        <div className="experience1">
          <textarea
            id="experience"
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
          />
        </div>
      </div>

      <div>
        <button className="share" onClick={handleShareExperience}>
          Bagikan Pengalaman
        </button>
        <Link to="/Afterlogin">
          <button className="kembali">Kembali</button>
        </Link>
      </div>

      <div className="experiences-list">
        <h2>Pengalaman Pengguna Lain:</h2>
        <ul>
          {experiences.map((exp, index) => (
            <li key={index}>
              <p>
                <strong>{exp.name}</strong> Memberikan Rating{" "}
                <strong>{exp.rating}</strong>
              </p>
              <p>{exp.experience}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Mentor5;
