import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../style/Beforelogin.css';
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import EduYouLogo from '../assets/eduyou.png';
import MaskGroupLogo from '../assets/mask-group1.png';
import MaskGroupLogo1 from '../assets/mask-group2.png';
import message1 from '../assets/message1.png';
import Reactlogo from '../assets/reactlogo.png';
import Htmllogo from '../assets/htmllogo.png';
import Csslogo from '../assets/csslogo.png';
import Jslogo from '../assets/jslogo.png';
import Figmalogo from '../assets/figmalogo.png';
import Mathlogo from '../assets/mathlogo.png';
import Biologylogo from '../assets/biologylogo.png';
import Indologo from '../assets/indologo.png';
import Inggrislogo from '../assets/inggrislogo.png';
import Reactmentor from '../assets/reactmentor.png';
import Htmlmentor from '../assets/htmlmentor.png';
import Cssmentor from '../assets/cssmentor.png';
import Jsmentor from '../assets/jsmentor.png';
import Figmamentor from '../assets/figmamentor.png';
import Indomentor from '../assets/indomentor.png';
import { FiUser, FiBookOpen } from "react-icons/fi";

const BeforeLogin = () => {

  useEffect(() => {
    function navigateToSection() {
      var section = document.getElementById("temukan-mentor");
      section.scrollIntoView({ behavior: "smooth" });
    }

    const links = document.querySelectorAll('.menu-link');
    links.forEach(link => {
      link.addEventListener('click', navigateToSection);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', navigateToSection);
      });
    };
  }, []);
  
  return (
    <div className="before-login-container">
      <img className="logo" src={EduYouLogo} alt="EduYou" />
      <div className="menu-container">
        <Link to="/login" className="menu-link">
          <div className="menu-item">
            <FiUser className="menu-icon" />
            <span>Temukan Mentor</span>
          </div>
        </Link>
        <Link to="/login" className="menu-link">
          <div className="menu-item">
            <FiBookOpen className="menu-icon" />
            <span>Kursus</span>
          </div>
        </Link>
        <Link to="/login" className="menu-link">
          <div className="menu-item">
          <FiUser className="menu-icon" />
            <span>Login?</span>
          </div>
        </Link>
      </div>
      <div className="before-login1">
        <header className="header">
        </header>
        <img src={MaskGroupLogo} alt="MaskGroup logo"/>
        <h1 className="belajar-online">Belajar Online kini jauh lebih mudah</h1>
        <h3>Di EduYou, kami percaya pada kekuatan pendidikan yang melampaui batas.
           Platform online kami dirancang untuk menghadirkan pengalaman kelas di ujung jari Anda, menjadikan pembelajaran menjadi perjalanan yang benar-benar mendalam dan nyaman.</h3>
      </div>
      <div className="message-1">
        <img src={message1} alt="Message1" className="message-image-before" />
      </div>
        <p className="message-before">"Apa Kamu Siap Menyusul Materi</p>
        <p className="message-before1">Diperkuliahan Bersama EduYou?"</p>
      <div className="caption1">
        <h2>All-In-One Solution</h2>
      </div>
      <div className="caption2">
        <h3>Banyak Materi-Materi Menarik Tersedia Khusus Bagi Mahasiswa.</h3>
      </div>
      <div className="frame1">
        <button className="frame-button">
          <img src={Reactlogo} alt="Reactlg logo"/>
          <h3>React.js</h3>
        </button>
        <button className="frame-button">
          <img src={Htmllogo} alt="Htmllg logo"/>
          <h3>HTML</h3>
        </button>
        <button className="frame-button">
          <img src={Csslogo} alt="Csslg logo"/>
          <h3>CSS</h3>
        </button>
        <button className="frame-button">
          <img src={Jslogo} alt="Jslg logo"/>
          <h3>JavaScript</h3>
        </button>
        <button className="frame-button">
          <img src={Figmalogo} alt="Figma logo"/>
          <h3>Desain</h3>
        </button> 
        <button className="frame-button">
          <img src={Indologo} alt="Indolg logo"/>
          <h3>Bahasa</h3>
          <h3>Indonesia</h3>
        </button> 
        <button className="frame-button">
          <img src={Inggrislogo} alt="Inggrislg logo"/>
          <h3>Bahasa</h3>
          <h3>Inggris</h3>
        </button>  
        <button className="frame-button">
          <img src={Mathlogo} alt="Mathlg logo"/>
          <h3>Math</h3>
        </button> 
        <button className="frame-button">
          <img src={Biologylogo} alt="Biology logo"/>
          <h3>Biology</h3>
        </button> 
      </div>
      <div className="caption3" id="temukan-mentor">
        <h2>Temukan Mentor Mu.</h2>
      </div>
      <div className="frame-mentor-before">
        <Link to="/login" className="menu-link"> {/* Tambahkan Link untuk menuju halaman Mentor1 */}
          <button className="frame-button1">
            <img src={Reactmentor} alt="React mentor"/>
            <h2>Adam Levin</h2>
            <h3>React.js</h3>
            <div className="icon-star">
              <FaStar className="star-icon" />
              <FaStar className="star-icon" />
              <FaStar className="star-icon" />
              <FaStar className="star-icon" />
              <FaStar className="star-icon" />
            </div>
            <div className="Rate">
              <p>100% Rate</p>
              <p>Rp.200.000,00</p>
          </div>
            <h4>"React.js memungkinkan kita untuk membangun antarmuka pengguna yang dinamis dan responsif dengan mudah. 
              Mari kita telusuri bersama konsep-konsep dasar, 
              kemudian menggali lebih dalam ke dalam praktik terbaik dan teknik canggih."</h4>
          </button>
        </Link>
        <Link to="/login" className="menu-link">
        <button className="frame-button1">
          <img src={Htmlmentor} alt="Html mentor"/>
          <h2>Tiara Victory</h2>
          <h3>HTML</h3>
          <div className="icon-star">
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
          </div>
          <div className="Rate">
              <p>100% Rate</p>
              <p>Rp.180.000,00</p>
          </div>
          <h4>"HTML adalah fondasi dari web modern. Mari kita pelajari cara membuat struktur dasar halaman web dengan benar, 
            menambahkan elemen-elemen penting, dan memahami pentingnya semantik untuk aksesibilitas dan SEO yang lebih baik."</h4>
        </button>
      </Link>
      <Link to="/login" className="menu-link">
        <button className="frame-button1">
          <img src={Cssmentor} alt="Css mentor"/>
          <h2>Klara Troye</h2>
          <h3>CSS</h3>
          <div className="icon-star">
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
          </div>
          <div className="Rate">
              <p>100% Rate</p>
              <p>Rp.120.000,00</p>
          </div>
          <h4>"CSS memberi kita kekuatan untuk membuat desain yang menarik dan responsif. 
            Ayo eksplorasi berbagai properti dan teknik dalam CSS untuk menciptakan tata letak yang indah, efisien, 
            dan terstruktur dengan baik."</h4>
        </button>
      </Link>
      <Link to="/login" className="menu-link">
        <button className="frame-button1">
          <img src={Jsmentor} alt="Js mentor"/>
          <h2>Joe Tan</h2>
          <h3>JavaScript</h3>
          <div className="icon-star">
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStarHalfAlt className="star-icon"/>
          </div>
          <div className="Rate">
              <p>100% Rate</p>
              <p>Rp.200.000,00</p>
          </div>
          <h4>"JavaScript adalah bahasa pemrograman yang memungkinkan kita untuk memberikan interaktivitas dan dinamika pada situs web. 
            Mari kita pelajari dasar-dasar bahasa, konsep pemrograman fungsional, dan bagaimana menerapkan JavaScript modern dengan keahlian."</h4>
        </button>
      </Link>
      <Link to="/login" className="menu-link">
        <button className="frame-button1">
          <img src={Figmamentor} alt="Figma mentor"/>
          <h2>Leona</h2>
          <h3>Bahasa Indonesia</h3>
          <div className="icon-star">
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
          </div>
          <div className="Rate">
              <p>100% Rate</p>
              <p>Rp.140.000,00</p>
          </div>
          <h4>" Bahasa Indonesia adalah proses mengajarkan dan mempelajari Bahasa Indonesia, 
            baik sebagai bahasa ibu maupun sebagai bahasa kedua."</h4>
        </button>
        </Link>
        <Link to="/login" className="menu-link"> 
        <button className="frame-button1">
          <img src={Indomentor} alt="Indo mentor"/>
          <h2>Anneth</h2>
          <h3>Desain</h3>
          <div className="icon-star">
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStarHalfAlt className="star-icon"/>
          </div>
          <div className="Rate">
              <p>100% Rate</p>
              <p>Rp.180.000,00</p>
          </div>
          <h4>"Desain adalah proses perencanaan dan penciptaan solusi untuk berbagai masalah melalui penggunaan elemen visual, fungsional, dan estetika. 
            Dalam konteks digital, desain sering merujuk pada desain antarmuka pengguna (UI) dan pengalaman pengguna (UX) untuk aplikasi dan situs web."
          </h4>
        </button>
      </Link>
      <Link to="/login" className="menu-link"> 
        <button className="frame-button1">
          <img src={Jsmentor} alt="Js mentor"/>
          <h2>Andrew</h2>
          <h3>Bahasa Inggris</h3>
          <div className="icon-star">
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStarHalfAlt className="star-icon"/>
          </div>
          <div className="Rate">
              <p>100% Rate</p>
              <p>Rp.150.000,00</p>
          </div>
          <h4>"Bahasa Inggris adalah proses di mana individu mempelajari keterampilan dan 
            pengetahuan yang diperlukan untuk menggunakan bahasa Inggris dengan efektif dalam berbagai konteks. "</h4>
        </button>
      </Link>
      <Link to="/login" className="menu-link">
        <button className="frame-button1">
          <img src={Figmamentor} alt="Figma mentor"/>
          <h2>Tyna Lou</h2>
          <h3>Matematika</h3>
          <div className="icon-star">
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
          </div>
          <div className="Rate">
              <p>100% Rate</p>
              <p>Rp.150.000,00</p>
          </div>
          <h4>"matematika adalah proses di mana individu mempelajari konsep, keterampilan, 
            dan aplikasi matematika yang diperlukan untuk memahami dan memecahkan masalah secara efektif. </h4>
        </button>
      </Link>
      <Link to="/login" className="menu-link"> 
        <button className="frame-button1">
          <img src={Cssmentor} alt="Css mentor"/>
          <h2>Liliana</h2>
          <h3>Biologi</h3>
          <div className="icon-star">
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
          </div>
          <div className="Rate">
              <p>100% Rate</p>
              <p>Rp.140.000,00</p>
          </div>
          <h4>"Biologi adalah ilmu yang mempelajari kehidupan dan organisme hidup, 
            termasuk struktur, fungsi, pertumbuhan, evolusi, persebaran, dan taksonominya."</h4>
        </button> 
      </Link>
      </div>
      <div className="caption4">
        <footer>
          <h1>Apa Kata Mereka?</h1>
        </footer>
        <main>
        <img src={MaskGroupLogo1} alt="Maskgroup1 logo"/>
          <div>
            <h3>EduYou mendapatkan lebih dari 100 ribu peringkat positif dari pengguna kami di seluruh dunia. Beberapa siswa dan guru sangat terbantu dengan adanya EduYou.</h3>
            <h3>Apakah kamu juga?</h3>
          </div>
        </main>
      </div>
        <div className="caption5">
            <h3>"Terima kasih banyak atas bantuan Anda. Ini persis seperti yang saya cari selama ini. 
              Ini benar-benar menghemat waktu dan tenaga saya."</h3>
            <h3>Gloria Rose</h3>
            <div className="icon-star">
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStar className="star-icon" />
            <FaStarHalfAlt className="star-icon"/>
          </div>
          <div className="caption-footer-before">
            <p>12 Ulasan</p>
          </div>
        </div>
          <div className="footer">
            <h4>React.js</h4>
            <h4>Html</h4>
            <h4>Css</h4>
            <h4>javaScript</h4>
            <h4>Bahasa Indonesia</h4>
            <h4>Desaign</h4>
            <h4>Bahasa Inggris</h4>
            <h4>Matematika</h4>
            <h4>Biologi</h4>
            <h5>© 2024 Webbers Team. </h5>
      </div>
    </div>
  );
};

export default BeforeLogin;
