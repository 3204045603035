// Profile.jsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import '../style/profile.css';
import useSignOut from 'react-auth-kit/hooks/useSignOut';

const Profile = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [currentUsername, setCurrentUsername] = useState('');
  const signOut = useSignOut();
  const navigate = useNavigate();


  const handleLogout = () => {
    signOut();
    if (signOut) {
      navigate("/beforeLogin");
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://eduyouwebbers.my.id/api/update-profile', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password, currentUsername }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log(data.message); // Success message
      } else {
        console.error(data.message); // Error message
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="container">
      <div className="profile-content">
        <h1>Welcome to Your Profile</h1>

        <form onSubmit={handleFormSubmit}>
          <label>
            Username Anda:
            <input
              type="text"
              value={currentUsername}
              onChange={(e) => setCurrentUsername(e.target.value)}
              required
            />
          </label>
          <label>
            Username Baru :
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </label>
          <label>
            Password Baru :
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <button type="submit">Update Profile</button>
        </form>

        <div className="button-container">
          <Link to="/afterlogin" className="back-button">
            Kembali
          </Link>
        </div>

        <button className="menu-link" onClick={handleLogout}>
          <div className="menu-item">
            <RiLogoutBoxRLine className="menu-icon" />
            <span>Logout</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Profile;
