import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import '../style/Soal.css';

const Soal3 = () => {
  const questions = [
    {
      question: 'Berapakah hasil dari 7 + 5 x 2?',
      options: ['60', '70', '50', '40'],
      answer: '70'
    },
    {
      question: 'Berapakah hasil dari 9 × 3?',
      options: ['21', '27', '28', '29'],
      answer: '27'
    },
    {
      question: 'Jika suatu segitiga memiliki panjang alas 8 cm dan tinggi 5 cm, berapakah luas segitiga tersebut?',
      options: ['40 cm²', '20 cm²', ' 30 cm²', '15 cm²'],
      answer: '20 cm²'
    }
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    if (selectedOption === questions[currentQuestion].answer) {
      setScore(score + 1);
    }

    setSelectedOption('');
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  const restartQuiz = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
  };

  return (
    <div className="quiz-container">
      {showScore ? (
        <div className="score-section">
          <h2>Skor kamu adalah {score} dari {questions.length}</h2>
          <button className="restart" onClick={restartQuiz}>Mulai ulang</button>
          <Link to="/course">
            <button className="kembali">Kembali</button>
          </Link>
        </div>
      ) : (
        <div>
          <div className="question-section">
            <div className="question-count">
              <span>Pertanyaan {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div className="question-text">{questions[currentQuestion].question}</div>
          </div>
          <div className="answer-section">
            {questions[currentQuestion].options.map(option => (
              <button key={option} onClick={() => handleOptionClick(option)}>{option}</button>
            ))}
          </div>
          <button className="next-question" onClick={handleNextQuestion} disabled={!selectedOption}>
            Selanjutnya
          </button>
        </div>
      )}
    </div>
  );
};

export default Soal3;
