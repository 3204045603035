import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import "../style/Payment.css";
import briImage from "../assets/bri.png";
import bniImage from "../assets/bni.png";
import bcaImage from "../assets/bca.png";
import danaImage from "../assets/dana.png";
import qrisImage from "../assets/qris.png";
import gopayImage from "../assets/gopay.png";
import indomartImage from "../assets/indomart.png";
import alfamartImage from "../assets/alfamart.png";
import ovoImage from "../assets/ovo.png";
import mandiriImage from "../assets/mandiri.png";
import Reactmentor from "../assets/reactmentor.png";
import Htmlmentor from "../assets/htmlmentor.png";
import Cssmentor from "../assets/cssmentor.png";
import Jsmentor from "../assets/jsmentor.png";
import Figmamentor from "../assets/figmamentor.png";
import Indomentor from "../assets/indomentor.png";
import Mathlogo from "../assets/mathlogo.png";
import Biologylogo from "../assets/biologylogo.png";
import Inggrislogo from "../assets/inggrislogo.png";

const mentors = [
  {
    id: "Mentor1",
    name: "Adam Levin",
    specialization: "React.js",
    rating: 5,
    price: "200000.00",
    image: Reactmentor,
  },
  {
    id: "Mentor2",
    name: "Tiara Victory",
    specialization: "HTML",
    rating: 5,
    price: "180000.00",
    image: Htmlmentor,
  },
  {
    id: "Mentor3",
    name: "Klara Troye",
    specialization: "CSS",
    rating: 5,
    price: "140000.00",
    image: Cssmentor,
  },
  {
    id: "Mentor4",
    name: "Joe Tan",
    specialization: "JavaScript",
    rating: 5,
    price: "200000.00",
    image: Jsmentor,
  },
  {
    id: "Mentor5",
    name: "Leona",
    specialization: "Desain",
    rating: 5,
    price: "140000.00",
    image: Figmamentor,
  },
  {
    id: "Mentor6",
    name: "Anneth",
    specialization: "Indonesian Language",
    rating: 5,
    price: "180000.00",
    image: Indomentor,
  },
  {
    id: "Mentor7",
    name: "Andrew",
    specialization: "English Language",
    rating: 5,
    price: "150000.00",
    image: Inggrislogo,
  },
  {
    id: "Mentor8",
    name: "Tyna Lou",
    specialization: "Mathematics",
    rating: 5,
    price: "150000.00",
    image: Mathlogo,
  },
  {
    id: "Mentor9",
    name: "Liliana",
    specialization: "Biology",
    rating: 5,
    price: "140000.00",
    image: Biologylogo,
  },
];

const PaymentTest = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mentorNameFromUrl = queryParams.get("mentorName");
  const courseTaughtFromUrl = queryParams.get("courseTaught");

  const [amount, setAmount] = useState("");
  const [mentorName, setMentorName] = useState("");
  const [mentorPrice, setMentorPrice] = useState("");
  const [courseTaught, setCourseTaught] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    loadSnapScript();

    if (mentorNameFromUrl && courseTaughtFromUrl) {
      setMentorName(mentorNameFromUrl);
      setCourseTaught(courseTaughtFromUrl);
      console.log("Mentor name from URL:", mentorNameFromUrl);
      console.log("Course taught from URL:", courseTaughtFromUrl);

      const mentor = mentors.find((m) => m.name === mentorNameFromUrl);
      if (mentor) {
        setMentorPrice(mentor.price);
        setAmount(mentor.price);
        console.log("Mentor found:", mentor);
      } else {
        console.log("Mentor not found");
      }
    }
  }, [mentorNameFromUrl, courseTaughtFromUrl]);

  const handlePayment = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://eduyouwebbers.my.id/api/charge", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          transaction_details: {
            order_id: "order-" + Date.now(),
            gross_amount: mentorPrice,
          },
          credit_card: {
            secure: true,
          },
          customer_details: {
            first_name: "Tirta",
            last_name: "Yudha",
            email: "tirta@example.com",
            phone: "08111222333",
          },
        }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Transaction successful:", data);
        alert("Transaction successful");
        if (window.snap) {
          window.snap.pay(data.token);
          setPaymentSuccess(true);
          localStorage.setItem("selectedMentor", mentorName); // Store mentorName
        } else {
          console.error("Snap.js is not loaded");
          alert("Payment script is not loaded, please refresh the page");
        }
      } else {
        console.log("Transaction failed:", data);
        alert("Transaction failed");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred during the transaction");
    } finally {
      setLoading(false);
    }
  };

  const loadSnapScript = () => {
    const script = document.createElement("script");
    script.src = "https://app.sandbox.midtrans.com/snap/snap.js";
    script.setAttribute("data-client-key", "SB-Mid-client-jLZSff4kkWJLI4Hj");
    script.onload = () => console.log("Snap.js loaded");
    document.body.appendChild(script);
  };

  if (paymentSuccess) {
    return <Navigate to="/afterlogin" />;
  }

  return (
    <div className="payment-container">
      <div className="payment-frame">
        <h1 className="pembayaran">Pembayaran</h1>
        <div className="bank-logos">
          <img className="bri" src={briImage} alt="BRI" />
          <img className="bni" src={bniImage} alt="BNI" />
          <img className="bca" src={bcaImage} alt="BCA" />
          <img className="dana" src={danaImage} alt="DANA" />
          <img className="qris" src={qrisImage} alt="QRIS" />
          <img className="gopay" src={gopayImage} alt="GOPAY" />
          <img className="indomart" src={indomartImage} alt="Indomart" />
          <img className="alfamart" src={alfamartImage} alt="Alfamart" />
          <img className="ovo" src={ovoImage} alt="OVO" />
          <img className="mandiri" src={mandiriImage} alt="Mandiri" />
        </div>
        <div className="form-group-payment">
          <label className="label1">Mentor:</label>
          <input
            className="input-payment"
            type="text"
            id="mentorName"
            value={mentorName}
            readOnly
          />
        </div>
        <div className="form-group-payment">
          <label className="label1">Harga:</label>
          <input
            className="input-payment"
            type="text"
            id="mentorPrice"
            value={mentorPrice}
            readOnly
          />
        </div>
        <div className="form-group-payment">
          <label className="label1">Mata Pelajaran:</label>
          <input
            className="input-payment"
            type="text"
            id="courseTaught"
            value={courseTaught}
            readOnly
          />
        </div>
        <div className="form-group-payment">
          <label className="label1">Jumlah:</label>
          <input
            className="input-payment"
            type="text"
            id="amount"
            value={amount}
            readOnly
          />
        </div>
        <button
          className="pay-button"
          onClick={handlePayment}
          disabled={loading}
        >
          {loading ? "Loading..." : "Bayar Sekarang"}
        </button>
      </div>
    </div>
  );
};

export default PaymentTest;
