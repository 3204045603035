import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import '../style/Soal.css';

const Soal3 = () => {
  const questions = [
    {
      question: 'Apa sinonim dari kata "indah"?',
      options: ['jelek', 'cantik', 'buruk', 'seram'],
      answer: 'cantik'
    },
    {
      question: 'Manakah kata yang merupakan kata kerja?',
      options: ['meja', 'menulis', 'kursi', 'buku'],
      answer: 'menulis'
    },
    {
      question: 'Kalimat manakah yang menggunakan tanda baca yang benar?',
      options: ['Hari ini saya pergi ke pasar', 'Hari ini, saya pergi ke pasar.', 'Hari ini saya, pergi ke pasar', 'Hari ini saya pergi, ke pasar.'],
      answer: 'Hari ini, saya pergi ke pasar.'
    }
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    if (selectedOption === questions[currentQuestion].answer) {
      setScore(score + 1);
    }

    setSelectedOption('');
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  const restartQuiz = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
  };

  return (
    <div className="quiz-container">
      {showScore ? (
        <div className="score-section">
          <h2>Skor kamu adalah {score} dari {questions.length}</h2>
          <button className="restart" onClick={restartQuiz}>Mulai ulang</button>
          <Link to="/course">
            <button className="kembali">Kembali</button>
          </Link>
        </div>
      ) : (
        <div>
          <div className="question-section">
            <div className="question-count">
              <span>Pertanyaan {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div className="question-text">{questions[currentQuestion].question}</div>
          </div>
          <div className="answer-section">
            {questions[currentQuestion].options.map(option => (
              <button key={option} onClick={() => handleOptionClick(option)}>{option}</button>
            ))}
          </div>
          <button className="next-question" onClick={handleNextQuestion} disabled={!selectedOption}>
            Selanjutnya
          </button>
        </div>
      )}
    </div>
  );
};

export default Soal3;
