import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import '../style/Mentorprofile.css'; // Pastikan path ini sesuai dengan struktur proyek Anda
import ReactVideo from '../video/react.js.mp4';
import HtmlVideo from '../video/html.mp4';
import CssVideo from '../video/css.mp4';
import JavascriptVideo from '../video/javascript.mp4';
import IndonesiaVideo from '../video/b.indonesia.mp4';
import DesignVideo from '../video/design.mp4';
import EnglishVideo from '../video/b.english.mp4';
import MathematichsVideo from '../video/mathematichs.mp4';
import BiologyVideo from '../video/biology.mp4';

const MentorProfile = () => {
  const { mentorName } = useParams(); // Dapatkan nama mentor dari URL

  // Data mentor termasuk nama, bio, keahlian, dan tautan Zoom
  const mentorData = {
    'Adam Levin': {
      name: 'Adam Levin',
      bio: 'Skilled developer with a focus on React.js.',
      Kursus: 'React.js',
      zoomLink: 'https://zoom.us/j/9876543210', // Contoh tautan Zoom
      sumber: ['https://www.youtube.com/watch?v=6kC6JncEOBY&list=PLc6SEcJkQ6DxQmmUzUilxRUNrJyeHDmX7'],
      referensi: ['https://www.w3schools.com/react/default.asp',
    'https://react.dev/learn', 'https://v2.scrimba.com/advanced-react-c02h']
    },
    'Tiara Victory': {
      name: 'Tiara Victory',
      bio: 'Experienced web developer specializing in HTML.',
      Kursus: 'HTML',
      zoomLink: 'https://zoom.us/j/1234567890', // Contoh tautan Zoom
      sumber: ['https://www.youtube.com/watch?v=71a2zeC71gk&list=PLc6SEcJkQ6DzVY6THm3prtUU6HKMqpZSH'],
      referensi: ['https://www.w3schools.com/html/default.asp',
      'https://developer.mozilla.org/en-US/docs/Web/HTML','https://v2.scrimba.com/learn-html-and-css-c0p']
    },
    'Klara Troye': {
      name: 'Klara Troye',
      bio: 'Experienced web developer specializing in CSS.',
      Kursus: 'CSS',
      zoomLink: 'https://zoom.us/j/1234567890', // Contoh tautan Zoom
      sumber: ['https://www.youtube.com/watch?v=4ZHSZ4bz_pE'],
      referensi: ['https://www.w3schools.com/css/default.asp',
    'https://developer.mozilla.org/en-US/docs/Web/CSS', 'https://v2.scrimba.com/learn-css-animations-c02g']
    },
    'Joe Tan': {
      name: 'Joe Tan',
      bio: 'Experienced web developer specializing in JavaScript.',
      Kursus: 'JavaScript',
      zoomLink: 'https://zoom.us/j/1234567890', // Contoh tautan Zoom
      sumber: ['https://www.youtube.com/watch?v=mD6uSGSjgr4&list=PLc6SEcJkQ6DwiGCnVdCbWLqo6ceICD_4b'],
      referensi: ['https://www.w3schools.com/js/default.asp', 
      'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
      'https://www.freecodecamp.org/learn/javascript-algorithms-and-data-structures-v8/',
      'https://v2.scrimba.com/javascript-deep-dive-c0a']
    },
    'Leona': {
      name: 'Leona',
      bio: 'Experienced web developer specializing in Bahasa Indonesia.',
      Kursus: 'Bahasa Indonesia',
      zoomLink: 'https://zoom.us/j/1234567890', // Contoh tautan Zoom
      sumber: ['https://youtu.be/h-E2lNFHO8o?si=QRPVgZAKCy_cYpB6'],
      referensi: ['https://www.loecsen.com/id/pelajari-bahasa-indonesia#/id/Diskusi', 
      'https://www.studiobelajar.com/materi-bahasa-indonesia-sma-kelas-10-11-12/']
    },
    'Anneth': {
      name: 'Anneth',
      bio: 'Experienced web developer specializing in Desain.',
      Kursus: 'Design',
      zoomLink: 'https://zoom.us/j/1234567890', // Contoh tautan Zoom
      sumber: ['https://www.youtube.com/watch?v=k7cbBb2Ju5E&list=PLkaFTR_oezJ7txRw0AKPgqnmMAfO67cmT'],
      referensi: ['https://www.figma.com/login', 
      'https://www.canva.com/', 
      'https://v2.scrimba.com/from-figma-to-code-c02f']
    },
    'Andrew': {
      name: 'Andrew',
      bio: 'Experienced web developer specializing in Bahasa Inggris.',
      Kursus: 'Bahasa Inggris',
      zoomLink: 'https://zoom.us/j/1234567890', // Contoh tautan Zoom
      sumber: ['https://youtu.be/QC-Vf5yXslw?si=Yai3sUw1AHD1mptK'],
      referensi: ['https://www.bbc.co.uk/learningenglish/']
    },
    'Tyna Lou': {
      name: 'Tyna Lou',
      bio: 'Experienced web developer specializing in Matematika.',
      Kursus: 'Matematika',
      zoomLink: 'https://zoom.us/j/1234567890', // Contoh tautan Zoom
      sumber: ['https://youtu.be/BqM9SbVyt0w?si=APBvYqb8VNdTGja9'],
      referensi: ['https://www.studiobelajar.com/materi-matematika-sma-kelas-10-11-12/', 
      'https://repository.bbg.ac.id/bitstream/568/1/Buku_Ajar_Matematika_Dasar.pdf']
    },
    'Liliana': {
      name: 'Liliana',
      bio: 'Experienced web developer specializing in Biologi.',
      Kursus: 'Biologi',
      zoomLink: 'https://zoom.us/j/1234567890', // Contoh tautan Zoom
      sumber: ['https://youtu.be/dJ8OM4n_XRk?si=QtH8tMHY6e7LR2qf'],
      referensi: ['https://www.studiobelajar.com/category/biologi/',
      'https://repository.penerbitwidina.com/media/publications/341777-biologi-dasar-cdc41deb.pdf']
    },
    // Mentor lainnya...
  };

  const mentor = mentorData[mentorName];

  if (!mentor) {
    return <p>Mentor tidak ditemukan</p>;
  }

  return (
    <div className="mentor-profile">
      <h1>{mentor.name}</h1>
      <p><strong>Bio:</strong> {mentor.bio}</p>
      <p><strong>Kursus:</strong> {mentor.Kursus}</p>
      <p><strong>Zoom Link:</strong> <a href={mentor.zoomLink}>{mentor.zoomLink}</a></p>
      <p><strong>Sumber Video:</strong></p>
      <ul>
        {mentor.sumber.map((source, index) => (
          <li key={index}><a href={source}>{source}</a></li>
        ))}
      </ul>
      <p><strong>Referensi:</strong></p>
      <ul>
        {mentor.referensi.map((ref, index) => (
          <li key={index}><a href={ref}>{ref}</a></li>
        ))}
      </ul>
      
      {/* Tambahkan video khusus untuk masing-masing mentor */}
      {mentor.name === 'Adam Levin' && (
        <div className="video-container">
          <video controls width="600">
            <source src={ReactVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {mentor.name === 'Tiara Victory' && (
        <div className="video-container">
          <video controls width="600">
            <source src={HtmlVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {mentor.name === 'Klara Troye' && (
        <div className="video-container">
          <video controls width="600">
            <source src={CssVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {mentor.name === 'Joe Tan' && (
        <div className="video-container">
          <video controls width="600">
            <source src={JavascriptVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {mentor.name === 'Leona' && (
        <div className="video-container">
          <video controls width="600">
            <source src={IndonesiaVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {mentor.name === 'Anneth' && (
        <div className="video-container">
          <video controls width="600">
            <source src={DesignVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {mentor.name === 'Andrew' && (
        <div className="video-container">
          <video controls width="600">
            <source src={EnglishVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {mentor.name === 'Tyna Lou' && (
        <div className="video-container">
          <video controls width="600">
            <source src={MathematichsVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {mentor.name === 'Liliana' && (
        <div className="video-container">
          <video controls width="600">
            <source src={BiologyVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {/* Tambahkan link untuk navigasi ke halaman Soal1.js */}
      {mentor.Kursus === 'React.js' && (
        <Link to="/soal1">
          <button className="soal">Lihat Soal</button>
        </Link>
      )}
      {mentor.Kursus === 'HTML' && (
        <Link to="/soal2">
          <button className="soal">Lihat Soal</button>
        </Link>
      )}
      {mentor.Kursus === 'CSS' && (
        <Link to="/soal3">
          <button className="soal">Lihat Soal</button>
        </Link>
      )}
      {mentor.Kursus === 'JavaScript' && (
        <Link to="/soal4">
          <button className="soal">Lihat Soal</button>
        </Link>
      )}
      {mentor.Kursus === 'Bahasa Indonesia' && (
        <Link to="/soal5">
          <button className="soal">Lihat Soal</button>
        </Link>
      )}
      {mentor.Kursus === 'Design' && (
        <Link to="/soal6">
          <button className="soal">Lihat Soal</button>
        </Link>
      )}
      {mentor.Kursus === 'Bahasa Inggris' && (
        <Link to="/soal7">
          <button className="soal">Lihat Soal</button>
        </Link>
      )}
      {mentor.Kursus === 'Matematika' && (
        <Link to="/soal8">
          <button className="soal">Lihat Soal</button>
        </Link>
      )}
      {mentor.Kursus === 'Biologi' && (
        <Link to="/soal9">
          <button className="soal">Lihat Soal</button>
        </Link>
      )}
      <Link to="/course">
        <button className="kembali">Kembali</button>
      </Link>
    </div>
  );
};

export default MentorProfile;
