import createStore from "react-auth-kit/createStore";
import AuthProvider from "react-auth-kit/AuthProvider";

const store = createStore({
  authName: "_auth",
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocols === "https:"
});

const AuthContext = ({ children }) => {
  return <AuthProvider store={store}>{children}</AuthProvider>;
};

export default AuthContext;
