import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../style/Register.css';
import { FaUser, FaLock, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import EduYouLogo from '../assets/eduyou.png';

const RegisterForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [message, setMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false); // New state for popup visibility
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);
      formData.append('phoneNumber', phoneNumber);
      formData.append('email', email);
      formData.append('avatar', avatar);

      const response = await axios.post('https://eduyouwebbers.my.id/api/register', formData);

      if (response.status === 200) {
        setMessage('User registered successfully');
        setShowPopup(true); // Show popup
        setTimeout(() => {
          setShowPopup(false); // Hide popup after 5 seconds
          navigate('/login'); // Navigate after popup disappears
        }, 3000);
      } else {
        setMessage('Error registering user');
      }
    } catch (error) {
      setMessage('The Account Already Exists');
    }
  };

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  return (
    <div className="register-container">
      <h2>🦉Register</h2>
      <div className="image-container">
        <img className="image" id="cityImage" src={EduYouLogo} alt="EduYou" />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="input-box">
          <p className="upload-photo">Upload Photo</p>
          <input
            type="file"
            accept="image/*"
            onChange={handleAvatarChange}
          />
        </div>
        <div className="input-box">
          <FaUser className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-box">
          <FaLock className="icon" />
          <input
            className="input-field"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="input-box">
          <MdEmail className="icon" />
          <input
            className="input-field"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-box">
          <FaPhoneAlt className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <button className="submit-button" type="submit">Register</button>
      </form>
      {showPopup && <div className="popup-register"></div>} {/* Popup message */}
      {showPopup && <div className="popup-register1">User registered successfully</div>} {/* Popup message */}
      <p className="message">{message}</p>
      <button className="switch-button" onClick={() => navigate('/login')}>Switch to Login</button>
    </div>
  );
};

export default RegisterForm;
