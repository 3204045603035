import React, { useState } from "react";
import "../style/Login.css";
import axios from "axios";
import { FaUser, FaLock } from "react-icons/fa";
import EduYouLogo from "../assets/eduyou.png";
import { useNavigate } from "react-router-dom";
import useSignIn from "react-auth-kit/hooks/useSignIn";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const signIn = useSignIn();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://eduyouwebbers.my.id/api/login", {
        username,
        password,
      });
      console.log("Server response:", response.data); // Debug log

      const { token, userId } = response.data;

      if (
        signIn({
          auth: {
            token: token,
            type: "Bearer",
          },
          userState: {
            name: username,
            userId: userId,
          },
        })
      ) {
        setSuccessMessage("Welcome 👋😉");
        setErrorMessage("");
        setShowPopup(true); // Show popup
        setTimeout(() => {
          setShowPopup(false); // Hide popup after 5 seconds
          navigate("/afterlogin", { state: { username } }); // Navigate after popup disappears
        }, 2000);
      } else {
        setErrorMessage("Login failed");
        setSuccessMessage("");
        setShowPopup(false); // Ensure popup is not shown on error
      }
    } catch (error) {
      console.error("Login error:", error); // Debug log
      setErrorMessage("Invalid username or password");
      setSuccessMessage("");
      setShowPopup(false); // Ensure popup is not shown on error
    }
  };

  const handleRegisterClick = () => {
    navigate("/register");
  };

  return (
    <div className="login-container">
      <h2>🦉Login</h2>
      <div className="image-container">
        <img className="image" id="cityImage" src={EduYouLogo} alt="EduYou" />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="input-box">
          <FaUser className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-box">
          <FaLock className="icon" />
          <input
            className="input-field"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className="submit-button" type="submit">
          Login
        </button>
      </form>
      {showPopup && <div className="popup-login"></div>} {/* Popup message */}
      {showPopup && <div className="popup-login1">Login Success!!</div>}{" "}
      {/* Popup message */}
      {successMessage && <p className="message">{successMessage}</p>}
      {errorMessage && <p className="message">{errorMessage}</p>}
      <p className="switch-text">
        Don't have an account?{" "}
        <button className="register-button" onClick={handleRegisterClick}>
          Register Now🦉
        </button>
      </p>
    </div>
  );
};

export default LoginForm;
