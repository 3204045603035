import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import '../style/Soal.css';

const Soal1 = () => {
  const questions = [
    {
      question: 'Apa itu React.js?',
      options: ['A. Sebuah framework untuk pengembangan backend', 'B. Sebuah pustaka JavaScript untuk membangun antarmuka pengguna', 
      'C. Sebuah bahasa pemrograman baru', 'D. Sebuah pustaka untuk pengelolaan database'],
      answer: 'B. Sebuah pustaka JavaScript untuk membangun antarmuka pengguna'
    },
    {
      question: 'Apa fungsi utama dari state dalam React?',
      options: ['A. Untuk menyimpan data sementara yang hanya digunakan dalam satu komponen', 'B. Untuk mengirim data dari satu komponen ke komponen lainnya', 
      'C. Untuk membuat gaya visual komponen', 'D. Untuk mengelola rute dalam aplikasi React'],
      answer: 'A. Untuk menyimpan data sementara yang hanya digunakan dalam satu komponen'
    },
    {
      question: 'Komponen mana yang paling tepat untuk menangani pengaturan logika dan tampilan yang kompleks dalam React?',
      options: ['A. Komponen Kelas (Class Component)', 'B. Komponen Fungsi (Function Component)', 
      'C. Komponen HTML Biasa', 'D. Komponen CSS'],
      answer: 'A. Komponen Kelas (Class Component)'
    }
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    if (selectedOption === questions[currentQuestion].answer) {
      setScore(score + 1);
    }

    setSelectedOption('');
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  const restartQuiz = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
  };

  return (
    <div className="quiz-container">
      {showScore ? (
        <div className="score-section">
          <h2>Skor kamu adalah {score} dari {questions.length}</h2>
          <button className="restart" onClick={restartQuiz}>Mulai ulang</button>
          <Link to="/course">
            <button className="kembali">Kembali</button>
          </Link>
        </div>
      ) : (
        <div>
          <div className="question-section">
            <div className="question-count">
              <span>Pertanyaan {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div className="question-text">{questions[currentQuestion].question}</div>
          </div>
          <div className="answer-section">
            {questions[currentQuestion].options.map(option => (
              <button key={option} onClick={() => handleOptionClick(option)}>{option}</button>
            ))}
          </div>
          <button className="next-question" onClick={handleNextQuestion} disabled={!selectedOption}>
            Selanjutnya
          </button>
        </div>
      )}
    </div>
  );
};

export default Soal1;
