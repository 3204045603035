import React, {  useState } from "react";
import { Link } from "react-router-dom";
import "../style/Afterlogin.css";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import EduYouLogo from "../assets/eduyou.png";
import MaskGroupLogo from "../assets/mask-group1.png";
import MaskGroupLogo1 from "../assets/mask-group2.png";
import message1 from "../assets/message1.png";
import Reactlogo from "../assets/reactlogo.png";
import Htmllogo from "../assets/htmllogo.png";
import Csslogo from "../assets/csslogo.png";
import Jslogo from "../assets/jslogo.png";
import Figmalogo from "../assets/figmalogo.png";
import Mathlogo from "../assets/mathlogo.png";
import Biologylogo from "../assets/biologylogo.png";
import Indologo from "../assets/indologo.png";
import Inggrislogo from "../assets/inggrislogo.png";
import Reactmentor from "../assets/reactmentor.png";
import Htmlmentor from "../assets/htmlmentor.png";
import Cssmentor from "../assets/cssmentor.png";
import Jsmentor from "../assets/jsmentor.png";
import Figmamentor from "../assets/figmamentor.png";
import Indomentor from "../assets/indomentor.png";
import { FiUser, FiBookOpen } from "react-icons/fi";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const AfterLogin = () => {

  // State untuk avatar dan pencarian
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const authUser = useAuthUser();

  const name = authUser.name;

  // Fungsi untuk melakukan pencarian mentor berdasarkan materi
  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    const filteredResults = mentors.filter((mentor) => {
      const mentorSpecialization = mentor.specialization.toLowerCase();
      return mentorSpecialization.includes(searchTerm.toLowerCase());
    });

    setSearchResults(filteredResults);
  };

  const scrollToMentorSection = () => {
    document.getElementById('mentor-section').scrollIntoView({ behavior: 'smooth' });
  };

  // Data mentor (contoh, sesuaikan dengan struktur data yang sesuai)
  const mentors = [
    {
      id: "Mentor1",
      name: "Adam Levin",
      specialization: "React.js",
      rating: 5,
      price: "Rp.200.000,00",
      description:
        '"React.js memungkinkan kita untuk membangun antarmuka pengguna yang dinamis dan responsif dengan mudah. Mari kita telusuri bersama konsep-konsep dasar, kemudian menggali lebih dalam ke dalam praktik terbaik dan teknik canggih."',
      image: Reactmentor,
    },
    {
      id: "Mentor2",
      name: "Tiara Victory",
      specialization: "HTML",
      rating: 5,
      price: "Rp.180.000,00",
      description:
        '"HTML adalah fondasi dari web modern. Mari kita pelajari cara membuat struktur dasar halaman web dengan benar, menambahkan elemen-elemen penting, dan memahami pentingnya semantik untuk aksesibilitas dan SEO yang lebih baik."',
      image: Htmlmentor,
    },
    {
      id: "Mentor3",
      name: "Klara Troye",
      specialization: "CSS",
      rating: 5,
      price: "Rp.140.000,00",
      description:
        '"CSS adalah bagian integral dari desain web modern. Mari kita jelajahi berbagai teknik styling dan layout untuk membuat tampilan yang menarik dan responsif."',
      image: Cssmentor,
    },
    {
      id: "Mentor4",
      name: "Joe Tan",
      specialization: "JavaScript",
      rating: 5,
      price: "Rp.200.000,00",
      description:
        '"JavaScript memungkinkan kita untuk membuat interaksi dinamis di dalam halaman web. Ayo pelajari bersama cara memanfaatkan JavaScript untuk meningkatkan fungsionalitas website Anda."',
      image: Jsmentor,
    },
    {
      id: "Mentor5",
      name: "Leona",
      specialization: "Indonesian Language",
      rating: 5,
      price: "Rp.140.000,00",
      description:
        '"Bahasa Indonesia adalah bagian penting dari budaya dan komunikasi. Ayo pelajari bersama bagaimana menguasai Bahasa Indonesia dengan baik dan benar."',
      image: Figmamentor,
    },
    {
      id: "Mentor6",
      name: "Anneth",
      specialization: "Design",
      rating: 5,
      price: "Rp.180.000,00",
      description:
        '"Figma adalah salah satu alat desain kolaboratif yang sangat kuat. Ayo pelajari bersama bagaimana memanfaatkan Figma untuk membuat desain yang menarik dan efisien."',
      image: Indomentor,
    },
    {
      id: "Mentor7",
      name: "Andrew",
      specialization: "English Language",
      rating: 5,
      price: "Rp.150.000,00",
      description:
        '"English Language opens doors to opportunities worldwide. Let\'s learn together how to master English effectively for various contexts."',
      image: Reactmentor,
    },
    {
      id: "Mentor8",
      name: "Tyna Lou",
      specialization: "Mathematics",
      rating: 5,
      price: "Rp.150.000,00",
      description:
        '"Mathematics is the language of science and engineering. Let\'s explore together the beauty and power of mathematical concepts."',
      image: Htmlmentor,
    },
    {
      id: "Mentor9",
      name: "Liliana",
      specialization: "Biology",
      rating: 5,
      price: "Rp.140.000,00",
      description:
        '"Biology is the study of life and living organisms. Let\'s dive deep into understanding the structures, functions, and evolution of life forms."',
      image: Cssmentor,
    },
    // Tambahkan data mentor lainnya sesuai kebutuhan
  ];

  // Fungsi untuk merender bintang rating
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar className="star-icon" key={`star-full-${i}`} />);
    }
    if (hasHalfStar) {
      stars.push(<FaStarHalfAlt className="star-icon" key="star-half" />);
    }
    return stars;
  };

  return (
    <div className="after-login-container">
      <img className="logo" src={EduYouLogo} alt="EduYou" />
      <div className="menu-container">
        <Link to="/Afterlogin#temukan-mentor" className="menu-link">
          <div className="menu-item" onClick={scrollToMentorSection}>
            <FiUser className="menu-icon" />
            <span>Temukan Mentor</span>
          </div>
        </Link>
        <Link to="/course" className="menu-link">
          <div className="menu-item">
            <FiBookOpen className="menu-icon" />
            <span>Kursus</span>
          </div>
        </Link>
        <Link to="/profile" className="menu-link">
          <div className="menu-item">
            <FiUser className="menu-icon" />
            <span>Profile</span>
          </div>
        </Link>
      </div>
      <div className="user-profile">
        <h2>Welcome, {name}!</h2>
        <div className="image-profile">
          <img
            src={`http://eduyouwebbers.my.id/api/profile-picture/${name}`}
            alt="Avatar"
            className="avatar"
          />
        </div>
      </div>
      <div className="after-login1">
        <img src={MaskGroupLogo} alt="MaskGroup logo" />
        <h1>Belajar Online kini jauh lebih mudah</h1>
        <h3>
          Di EduYou, kami percaya pada kekuatan pendidikan yang melampaui batas.
          Platform online kami dirancang untuk menghadirkan pengalaman kelas di
          ujung jari Anda, menjadikan pembelajaran menjadi perjalanan yang
          benar-benar mendalam dan nyaman.
        </h3>
      </div>
      <div className="message1">
        <img src={message1} alt="Message1" className="message-image" />
      </div>
      <p className="message-after">"Apa Kamu Siap Menyusul Materi</p>
      <p className="message-after1">Diperkuliahan Bersama EduYou?"</p>
      <div className="caption-1">
        <h2>All-In-One Solution</h2>
      </div>
      <div className="caption-2">
        <h3>Banyak Materi-Materi Menarik Tersedia Khusus Bagi Mahasiswa.</h3>
      </div>
      <div className="frame-1">
        <button className="frame-button">
          <img src={Reactlogo} alt="Reactlg logo" />
          <h3>React.js</h3>
        </button>
        <button className="frame-button">
          <img src={Htmllogo} alt="Htmllg logo" />
          <h3>HTML</h3>
        </button>
        <button className="frame-button">
          <img src={Csslogo} alt="Csslg logo" />
          <h3>CSS</h3>
        </button>
        <button className="frame-button">
          <img src={Jslogo} alt="Jslg logo" />
          <h3>JavaScript</h3>
        </button>
        <button className="frame-button">
          <img src={Figmalogo} alt="Mysqllg logo" />
          <h3>Desain</h3>
        </button>
        <button className="frame-button">
          <img src={Indologo} alt="Indolg logo" />
          <h3>Bahasa</h3>
          <h3>Indonesia</h3>
        </button>
        <button className="frame-button">
          <img src={Inggrislogo} alt="Inggrislg logo" />
          <h3>Bahasa</h3>
          <h3>Inggris</h3>
        </button>
        <button className="frame-button">
          <img src={Mathlogo} alt="Mathlg logo" />
          <h3>Math</h3>
        </button>
        <button className="frame-button">
          <img src={Biologylogo} alt="Biology logo" />
          <h3>Biology</h3>
        </button>
      </div>
      <div className="caption-3" id="temukan-mentor">
      <div id="mentor-section">
        <h2>Temukan Mentor Mu.</h2>
        </div>
        {/* Input untuk pencarian mentor */}
        <div className="search-course">
          <input
            type="text"
            className="search-input"
            placeholder="Cari Materi..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="frame-mentor">
        {/* Render hasil pencarian jika ada, atau semua mentor jika tidak ada pencarian */}
        {searchResults.length > 0
          ? searchResults.map((mentor) => (
              <Link
                key={mentor.id}
                to={`/page/${mentor.id}`}
                className="menu-link"
              >
                <button className="frame-button1">
                  <img src={mentor.image} alt={mentor.name} />
                  <h2>{mentor.name}</h2>
                  <h3>{mentor.specialization}</h3>
                  <div className="icon-star">{renderStars(mentor.rating)}</div>
                  <div className="Rate">
                    <p>100% Rate</p>
                    <p>{mentor.price}</p>
                  </div>
                  <h4>{mentor.description}</h4>
                </button>
              </Link>
            ))
          : mentors.map((mentor) => (
              <Link
                key={mentor.id}
                to={`/page/${mentor.id}`}
                className="menu-link"
              >
                <button className="frame-button1">
                  <img src={mentor.image} alt={mentor.name} />
                  <h2>{mentor.name}</h2>
                  <h3>{mentor.specialization}</h3>
                  <div className="icon-star">{renderStars(mentor.rating)}</div>
                  <div className="Rate">
                    <p>100% Rate</p>
                    <p>{mentor.price}</p>
                  </div>
                  <h4>{mentor.description}</h4>
                </button>
              </Link>
            ))}
      </div>
      <div className="caption-4">
        <footer>
          <h1>Apa Kata Mereka?</h1>
        </footer>
        <main>
          <img src={MaskGroupLogo1} alt="Maskgroup1 logo" />
          <div>
            <h3>
              EduYou mendapatkan lebih dari 100 ribu peringkat positif dari
              pengguna kami di seluruh dunia. Beberapa siswa dan guru sangat
              terbantu dengan adanya EduYou.
            </h3>
            <h3>Apakah kamu juga?</h3>
          </div>
        </main>
      </div>
      <div className="caption-5">
        <h3>
          "Terima kasih banyak atas bantuan Anda. Ini persis seperti yang saya
          cari selama ini. Ini benar-benar menghemat waktu dan tenaga saya."
        </h3>
        <h3>Gloria Rose</h3>
        <div className="icon-star-caption5">
          <FaStar className="star-icon" />
          <FaStar className="star-icon" />
          <FaStar className="star-icon" />
          <FaStar className="star-icon" />
          <FaStarHalfAlt className="star-icon" />
        </div>
        <div className="caption-footer">
          <p>12 Ulasan</p>
        </div>
      </div>
      <div className="footer">
        <h4>React.js</h4>
        <h4>Html</h4>
        <h4>Css</h4>
        <h4>javaScript</h4>
        <h4>Bahasa Indonesia</h4>
        <h4>Desaign</h4>
        <h4>Bahasa Inggris</h4>
        <h4>Matematika</h4>
        <h4>Biologi</h4>
        <h5>© 2024 Webbers Team. </h5>
      </div>
    </div>
  );
};

export default AfterLogin;
